.home {
  width: 100%;
  align-items: center;
  font-family: "Arial", sans-serif;
  color: #3e497a;
}

.about {
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #21325e;
  color: #f0f0f0;
}

.about h2 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 80px;
  color: #e9d35b;
  height: 50px;
  /* color: rgb(161, 227, 28); */  
}
.about h2:hover{
    color:palevioletred;
}

.prompt p{
  color: burlywood;
  font-style: oblique;
}
.about .prompt {
  width: 40%;
  font-size: 30px;
}
.prompt svg {
  font-size: 60px;
  margin: 5px;
  color: white;
}

.skills {
  font-size: 35px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.skills h1 {
  color: #a3733876;
}
.skills h1:hover{
  color: brown;
}
.skills h3{
  color:rgba(11, 43, 36, 0.434);
}
.skills h3:hover{
  color: black;
}
.skills span{
  color: rgba(26, 111, 175, 0.797);
  font-size: larger;
  font-style: italic;
}
.skills span:hover{
  color:#21325e;
}

.skills .list {
  list-style: none;
  width: 60%;
}

.list span {
  font-size: 20px;
}

@media only screen and (max-width: 1010px) {
  .about h2 {
    font-size: 40px;
  }

  .about .prompt {
    margin-top: 10px;
    font-size: 20px;
    width: 80%;
  }

  .skills {
    text-align: center;
  }
  .list {
    padding: 0;
  }

  .skills h2 {
    font-size: 30px;
  }
}
