.project {
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.project h1 {
  margin-top: 130px;
  color: #c91c1c;
  font-size: 35px;
}
.project img {
  width: 700px;
  border-radius: 10px;
}

.project p {
  font-size: 40px;
  color: #21325e;
}

.project svg {
  font-size: 60px;
  color: black;
}
@media only screen and (max-width: 1300px){
  .project h1 {
    margin-top: 9rem;
    color: #c91c1c;
    font-size: 20px;
  }
  .project img {
    width: 300px;
    border-radius: 10px;
  }
  .project p {
    font-size: 20px;
    color: #21325e;
  }
}
